import {
  Breadcrumb,
  Button,
  Card,
  Icon,
  message,
  Popconfirm,
  Table,
} from "antd"
import { gql } from "apollo-boost"
import { Link, navigate } from "gatsby"
import moment from "moment"
import qs from "query-string"
import React from "react"
import { Mutation, Query } from "react-apollo"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const { Column } = Table
const pageSize = 10

const DELETE_STOCK_IN = gql`
  mutation deleteStockIn($id: bigint) {
    update_mbt_stock_ins(
      where: { id: { _eq: $id }, deleted_at: { _is_null: true } }
      _set: { deleted_at: "now()" }
    ) {
      affected_rows
    }
  }
`

const LIST_STOCK_INS = gql`
  query listStockIns($limit: Int, $offset: Int) {
    mbt_stock_ins_aggregate {
      aggregate {
        count
      }
    }
    mbt_stock_ins(limit: $limit, offset: $offset, order_by: { date: desc }) {
      id
      supplier {
        name
      }
      date
      no_doc
    }
  }
`

const GET_STOCK_IN = gql`
  query getStockIn($id: bigint) {
    mbt_stock_in_details(where: { stock_in_id: { _eq: $id } }) {
      item {
        id
        name
        unit
      }
      quantity
    }
  }
`

export default ({ location }) => {
  const { page: currentPage = 1 } = qs.parse(location.search, {
    parseNumbers: true,
  })
  return (
    <Layout>
      <SEO title="Stock In" />
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>
          <Link to="/">
            <Icon type="home" />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Stock In</Breadcrumb.Item>
      </Breadcrumb>
      <Query
        fetchPolicy="cache-and-network"
        query={LIST_STOCK_INS}
        variables={{ limit: pageSize, offset: (currentPage - 1) * pageSize }}
      >
        {({ data = {}, loading, refetch }) => (
          <Card>
            <div style={{ marginBottom: 16 }}>
              <Button
                type="primary"
                icon="plus"
                onClick={() => navigate("/stock-in/new/")}
              >
                New
              </Button>{" "}
              <Button icon="sync" loading={loading} onClick={() => refetch()}>
                Refresh
              </Button>
            </div>
            <Table
              bordered
              dataSource={data.mbt_stock_ins}
              expandedRowRender={({ id }) => (
                <Query
                  fetchPolicy="cache-and-network"
                  query={GET_STOCK_IN}
                  variables={{ id }}
                >
                  {({ data = {}, loading }) => (
                    <Table
                      dataSource={data.mbt_stock_in_details}
                      loading={loading}
                      rowKey={({ item }) => `${id}.${item.id}`}
                      pagination={false}
                      size="middle"
                    >
                      <Column title="Item" dataIndex="item.name" />
                      <Column
                        title="Quantity"
                        dataIndex="quantity"
                        render={(text, { item }) => `${text} ${item.unit}`}
                      />
                    </Table>
                  )}
                </Query>
              )}
              loading={loading}
              pagination={{
                current: currentPage,
                disabled: loading,
                pageSize,
                total: data.mbt_stock_ins_aggregate
                  ? data.mbt_stock_ins_aggregate.aggregate.count
                  : 0,
              }}
              rowKey="id"
              size="middle"
              onChange={pagination =>
                navigate(`/stock-in/?page=${pagination.current}`)
              }
            >
              <Column
                title="Date"
                dataIndex="date"
                render={text => moment(text).format("L")}
              />
              <Column title="Supplier" dataIndex="supplier.name" />
              <Column title="No doc" dataIndex="no_doc" />
              <Column
                title="Action"
                width={85}
                render={(text, { id }) => (
                  <React.Fragment>
                    <Button
                      disabled={loading}
                      icon="edit"
                      onClick={() => navigate(`/stock-in/edit?id=${id}`)}
                    />{" "}
                    <Mutation
                      mutation={DELETE_STOCK_IN}
                      update={(cache, { data: { update_mbt_stock_ins } }) =>
                        update_mbt_stock_ins.affected_rows && refetch()
                      }
                    >
                      {(deleteStockIn, { loading: deleteLoading }) => (
                        <Popconfirm
                          disabled={loading}
                          title={`Are you sure?`}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            message.info("Delete is temporarily disabled")
                          }
                        >
                          <Button
                            disabled={loading}
                            icon="delete"
                            loading={deleteLoading}
                            type="danger"
                          />
                        </Popconfirm>
                      )}
                    </Mutation>
                  </React.Fragment>
                )}
              />
            </Table>
          </Card>
        )}
      </Query>
    </Layout>
  )
}
